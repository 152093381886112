[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration
.illustration-section-01::after {
	@include illustration('8.svg', 100%, 286px, top);
}
// Hero Momo illustration
.illustration-section-momo::after {
	@include illustration('2.svg', 100%, 286px, top);
}

// Features illustration 02
.illustration-section-02::after {
	@include illustration('5.svg', 120%, 253px, null, null, 30px);
}

// Features illustration momo python
.illustration-section-momo-py::after{
	@include illustration('7.svg', 120%, 253px, null, null, 30px);
}

// Features illustration momo javascript
.illustration-section-momo-js::after{
	@include illustration('3.svg', 120%, 253px, null, null, 10px);
}